import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import AnimationContainer from 'components/animation-container'
import AnimatedHeading from 'components/animated-heading'
import { InnerContent, MainContainer, ImageWrapper } from 'components/container'
import ColumnContainer from 'components/column'
import { Separator } from 'components/separator'
import Metadata from '../../constants/metadata'
import Section from 'components/section'
import { Color, Spacing } from '../../constants/variables'
import { Heading2, Heading3, ParagraphText } from 'components/text'
import 'animate.css/animate.compat.css'

class ProcessPage extends React.Component {
  shouldComponentUpdate() { return false }

  renderItems(props) {
    const styles = {
      height: 'auto',
      width: '100%',
    }
    const itemsJSX = Metadata.Process.items.map((item, index) => {
      const rowKey = index + "_" + item.title + "_Row"
      const key = index + "_" + item.title + "_Image"
      return (
        <Row key={rowKey} style={{ marginTop: Spacing.four, marginBottom: Spacing.four }}>
          <Col md={5}>
            <ImageWrapper className="Illustration" key={key}>
              {item.illustration("", styles)}
            </ImageWrapper>
          </Col>
          <Col md={7}>
            <Heading3>{item.title}</Heading3>
            <Separator marginBottom={4} />
            <ParagraphText>{item.description}</ParagraphText>
          </Col>
        </Row>
      )
    })
    return itemsJSX
  }

  renderPageHeader() {
    return (
      <div>
        <AnimatedHeading text={Metadata.Process.header} /><Separator marginBottom={4} />
        <Heading3 style={{ marginBottom: Spacing.four }}>{Metadata.Process.subheader}</Heading3>
      </div>
    )
  }

  render() {
    return (
      <Section id="process" style={{ backgroundColor: Color.dark }}>
        <MainContainer>
          <Row>
            <ColumnContainer md={12}>
              <Col md={12}>
                <AnimationContainer animation="fadeIn">
                  <InnerContent>
                    {this.renderPageHeader()}
                    <Row>
                      {this.renderItems(this.props)}
                    </Row>
                  </InnerContent>
                </AnimationContainer>
              </Col>
            </ColumnContainer>
          </Row>
        </MainContainer>
      </Section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
    query {
        image: file(relativePath: {eq: "icons/mobile.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
    }
    `}
    render={({ image, abstract }) => <ProcessPage image={image} abstract={abstract} {...props} />}
  />
)